import React, { useState } from "react";
import "./App.css";
import { SpreadsheetComponent } from "@syncfusion/ej2-react-spreadsheet";
import { registerLicense } from "@syncfusion/ej2-base";

import ExcelData from "./components/ExcelData";
import styled from "styled-components";
import { useFormatMemberlists } from "./hooks/useFormatMemberlists";
import ChaletSpiegelViewPDF from "./components/ChaletSpiegelViewPDF";

registerLicense(
  "Mgo+DSMBaFt/QHRqVVhlXVpHaV1CQmFJfFBmQ2lbflR1cEUmHVdTRHRcQl9hQX5Qc0xgWnxYdnE=;Mgo+DSMBPh8sVXJ0S0J+XE9Bd1RBQmFJYVF2R2BJeVRydV9DZEwxOX1dQl9gSXxRfkVgXHZdc3dcRmU=;ORg4AjUWIQA/Gnt2VVhkQlFac15JXnxLfkx0RWFab1h6dlRMZVhBJAtUQF1hSn5RdE1jWnpXc3dRR2BZ;MTIyMDk5NUAzMjMwMmUzNDJlMzBTbTI4d09obTJxVTZpSU53YVJhRTZqWnpuY1RCMmRnK1RDWFVXKzdYdUlrPQ==;MTIyMDk5NkAzMjMwMmUzNDJlMzBPTGNuNld3TXJVaUFuSW1ISnk1QUd0NUdmTjh1ZnVtQXRxQ0MyeGE0OUVRPQ==;NRAiBiAaIQQuGjN/V0Z+WE9EaFpDVmBWfFNpR2NbfE52fldHallVVBYiSV9jS31TdEdrWXxbeXdWQmhUUw==;MTIyMDk5OEAzMjMwMmUzNDJlMzBENUU1TVJWRCtLK3pCcnVuQUlPeS9LeTZSWXFpOWJrYlB1LzhBclllcVIwPQ==;MTIyMDk5OUAzMjMwMmUzNDJlMzBCbjFxSWVZdm1JcGwveDZIWjhXSUFSYStEQ1E0QnlIeG8xMkZZRnFTZ3c0PQ==;Mgo+DSMBMAY9C3t2VVhkQlFac15JXnxLfkx0RWFab1h6dlRMZVhBJAtUQF1hSn5RdE1jWnpXc3dQTmFU;MTIyMTAwMUAzMjMwMmUzNDJlMzBNRW9jZEJuTG84a2lMcEZhUnBxQVowL1JVWVFPQWNQbUZDNHFIU0ZKVmtzPQ==;MTIyMTAwMkAzMjMwMmUzNDJlMzBZSll1RjBXMThncHBaU2lpT0RJZEU0VGw3NmVaSmJNVHQ2N1ZFSFBESVdnPQ==;MTIyMTAwM0AzMjMwMmUzNDJlMzBENUU1TVJWRCtLK3pCcnVuQUlPeS9LeTZSWXFpOWJrYlB1LzhBclllcVIwPQ=="
);

const Flex = styled.div`
  display: flex;
`;

export const App: React.FC = () => {
  const [masterplanData, setMasterplanData] = useState({});
  const [membersInput, setMembersInput] = useState({});

  const [wochenplanEnabled, setWochenplanEnabled] = useState(false);
  const [chaletspiegelEnabled, setChaletspiegelEnabled] = useState(false);
  const members = useFormatMemberlists(membersInput);

  return (
    <>
      <Flex>
        {Object.keys(membersInput).length ? (
          <>Teilnehmerliste upload erfolgreich</>
        ) : (
          <div style={{ width: "300px" }}>
            <h1>Teilnehmerliste</h1>
            <SpreadsheetComponent
              allowOpen={true}
              openComplete={(data) => {
                setMembersInput(data.response.data.sheets[0]);
                console.log(
                  "new memerslist data",
                  data.response.data.sheets[0]
                );
              }}
              openUrl="https://ej2services.syncfusion.com/production/web-services/api/spreadsheet/open"
            />
          </div>
        )}
        {Object.keys(masterplanData).length ? (
          <>Masterplan upload erfolgreich</>
        ) : (
          <div style={{ width: "300px" }}>
            <h1>Masterplan</h1>
            <SpreadsheetComponent
              allowOpen={true}
              openComplete={(data) => {
                setMasterplanData(data.response.data.sheets[0]);
                console.log(
                  "new masterplan data",
                  data.response.data.sheets[0]
                );
              }}
              openUrl="https://ej2services.syncfusion.com/production/web-services/api/spreadsheet/open"
            />
          </div>
        )}
        <div>
          Wochenplan
          <br />
          <div
            onClick={() => {
              setWochenplanEnabled(true);
            }}
          >
            EIN
          </div>
          <div
            onClick={() => {
              setWochenplanEnabled(false);
            }}
          >
            AUS
          </div>
          <br />
          <br />
          <br />
          <br />
          <br />
          Chaletspiegel
          <br />
          <div
            onClick={() => {
              setChaletspiegelEnabled(true);
            }}
          >
            EIN
          </div>
          <br />
          <br />
        </div>
      </Flex>
      {chaletspiegelEnabled && Object.keys(membersInput).length && (
        <ChaletSpiegelViewPDF members={members} />
      )}
      {wochenplanEnabled &&
        Object.keys(membersInput).length &&
        Object.keys(masterplanData).length && (
          <ExcelData masterplan={masterplanData} members={members} />
        )}
    </>
  );
};

export default App;

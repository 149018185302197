import React from "react";

import { Text, Image, View, StyleSheet, Font } from "@react-pdf/renderer";
import { daysToReplace } from "../defaults";

// Create styles
const styles = StyleSheet.create({
  dayView: {
    width: "2.85cm",
    marginBottom: "1cm",
  },
  flex: {
    display: "flex",
    flexWrap: "wrap",
  },
  styledDate: {
    fontSize: "10pt",
    fontWeight: "bold",
  },
  flexVertical: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
  },
  unitBlock: {
    width: "2.7cm",
    marginTop: "0.1cm",
    border: "1px solid black",
    padding: "0.1cm",
    fontSize: "7pt",
    marginBottom: "0.1cm",
  },
  title: {
    fontSize: "12pt",
    marginBottom: "0.2cm",
  },
  smallText: {
    fontSize: "9pt",
  },
  smallerText: {
    fontSize: "6pt",
  },
});

function checkForIdenticalFollowBlock(
  day: any,
  actualBlock: any,
  blockIndex: any,
  iteration: any
): any {
  let typeOfActualBlock = "";
  if (actualBlock.type) {
    typeOfActualBlock = actualBlock.type.typeName;
  }
  if (actualBlock.type && actualBlock.type.combineWithNextBlock) {
    let newBlockIndex = blockIndex + iteration;
    let newIteration = iteration + 1;
    let typeOfNextBlock = "";

    // If there is another block after the actual block
    if (newBlockIndex < day.length - 1) {
      // if the next block is not an undefined type
      if (day[newBlockIndex + 1].type !== undefined) {
        typeOfNextBlock = day[newBlockIndex + 1].type.typeName;
      }
    }
    if (
      typeOfNextBlock !== "" &&
      typeOfNextBlock !== undefined &&
      typeOfNextBlock === typeOfActualBlock
    ) {
      return checkForIdenticalFollowBlock(
        day,
        actualBlock,
        blockIndex,
        newIteration
      );
    } else {
      // if 0 is returned, next block is not the same. Else count for all next blocks.
      return newIteration;
    }
  } else {
    return 1;
  }
}

type DayViewPDFProps = {
  day: any;
};
export const DayViewPDF: React.FC<DayViewPDFProps> = ({ day }) => {
  let blocksToSkip = 0;
  let blockCountPerDay = 0;
  const dayWithoutMeals = day.blocks.filter((block: any) => block.meal === "");

  const kuechendienst = dayWithoutMeals.find(
    (block: any) => block.type && block.type.typeName === "Kue"
  );

  return (
    <View style={styles.dayView}>
      <Text style={styles.styledDate}>{day.blocks[0].date}</Text>
      <View style={styles.flexVertical}>
        {day.blocks[0].date in daysToReplace ? (
          <View>
            {kuechendienst && (
              <>
                <Text style={{ fontSize: "10pt", color: "#FF0000" }}>
                  {kuechendienst.type.specialTime}
                </Text>
                <Text style={{ fontSize: "10pt", color: "#FF0000" }}>
                  Küchendienst
                </Text>
              </>
            )}
            {daysToReplace[day.blocks[0].date].content}
          </View>
        ) : (
          <>
            {dayWithoutMeals.map((block: any, blockIndex: any) => {
              blockCountPerDay += 1;
              // Wenn wir Blöcke hatten, die über mehrere Zeilen gehen, werden die wiederholten Blöcke ausgelassen
              if (blocksToSkip > 1) {
                blocksToSkip--;
                return null; //activate skipping of blocks
              }
              // if block is mealblock, skip
              if (block.time === undefined) {
                return null;
              }
              let blockText = (block.type && block.type.text) || block.content;
              if (block.type) {
                if (block.type.typeName === "Conv") {
                  blockText = (
                    <>
                      <Text
                        style={{
                          fontFamily: "Helvetica-Bold",
                          fontWeight: "bold",
                          fontSize: "8pt",
                        }}
                      >
                        Conversation {block.type.subject}
                        {"\n"}
                      </Text>
                      <Text>{block.type.teacher}</Text>
                    </>
                  );
                } else if (block.type.typeName === "Kue") {
                  blockText = (
                    <Text style={{ color: "red" }}>Küchendienst</Text>
                  );
                } else if (block.type.typeName === "Ausflug") {
                  blockText = (
                    <>
                      <Text>Excursion{"\n"}</Text>
                      <Text>
                        {block.type.destination}
                        {"\n"}
                        {block.type.destination === "St-Tropez" &&
                          block.content &&
                          block.content + "\n\n"}
                      </Text>
                      {block.type.destination === "Port Grimaud" && (
                        <Image
                          src={`https://intra.sprachlager.fr/application_img/portgrimaud2.jpg`}
                        />
                      )}
                      {block.type.destination === "St-Tropez" && (
                        <Image
                          src={`https://intra.sprachlager.fr/application_img/StTropez.jpg`}
                        />
                      )}
                      {block.type.destination === "Grasse" && (
                        <Image
                          src={`https://intra.sprachlager.fr/application_img/Grasse.jpg`}
                        />
                      )}
                      {block.type.destination === "Nice" && (
                        <Image
                          src={`https://intra.sprachlager.fr/application_img/Nice.jpg`}
                        />
                      )}
                    </>
                  );
                }
              }

              let blockHeight = checkForIdenticalFollowBlock(
                dayWithoutMeals,
                block,
                blockIndex,
                0
              );

              // Nur halbe Blöcke für die erste Stunde am morgen
              if (block.time === "07-08 h") {
                blockHeight = 0.6;
              }

              // skip the next n blocks because we have one for them all
              blocksToSkip = blockHeight;
              let time = block.time;

              // if multiple blocks are pulled together use start time from first block and end time from last one
              if (blockHeight > 1) {
                if (
                  block.time.split("-").length &&
                  dayWithoutMeals[blockIndex + blockHeight - 1]
                ) {
                  time =
                    block.time.split("-")[0] +
                    " - " +
                    (dayWithoutMeals[blockIndex + blockHeight - 1].time.split(
                      "-"
                    ).length > 1 &&
                      dayWithoutMeals[blockIndex + blockHeight - 1].time.split(
                        "-"
                      )[1]);
                  /*
                  
                  
                  */
                }
              }
              // meal blocks
              let preBlock = <></>;
              if (day.meals.length) {
                if (time.includes("09")) {
                  day.meals
                    .filter((meal: any) => meal.includes("1: "))
                    .map((meal: any) => {
                      preBlock = (
                        <View style={{ height: "0.5cm" }}>
                          <Text style={styles.smallerText}>
                            {meal.slice(3)}
                          </Text>
                        </View>
                      );
                    });
                }
                if (time.includes("14")) {
                  day.meals
                    .filter((meal: any) => meal.includes("2: "))
                    .map((meal: any) => {
                      preBlock = (
                        <View style={{ height: "0.5cm" }}>
                          <Text style={styles.smallerText}>
                            {meal.slice(3)}
                          </Text>
                        </View>
                      );
                    });
                }
                if (time.includes("19") || time.includes("23")) {
                  day.meals
                    .filter((meal: any) => meal.includes("3: "))
                    .map((meal: any) => {
                      preBlock = (
                        <View style={{ height: "0.5cm" }}>
                          <Text style={styles.smallerText}>
                            {meal.slice(3)}
                          </Text>
                        </View>
                      );
                    });
                }
              }
              // if type overrides time
              if (block.type && block.type.specialTime) {
                time = block.type.specialTime;
              }
              return (
                <View key={blockIndex}>
                  {preBlock}
                  <View
                    style={{
                      ...styles.unitBlock,
                      backgroundColor: block.type && block.type.color,
                      height:
                        (1 * blockHeight + 0.1 * (blockHeight - 1)) * 1.3 +
                        "cm",
                      marginTop:
                        blockCountPerDay === 1 &&
                        (time.includes("09") || time.includes("07:30"))
                          ? "0.8cm"
                          : "0cm",
                    }}
                  >
                    {blockHeight === 0.6 ? (
                      <>
                        <Text>
                          {time} - {blockText}
                        </Text>
                      </>
                    ) : (
                      <>
                        <Text>{time}</Text>
                        {
                          // block types with not just text in them, eg. images. Dont wrap a text block around an img...
                          block.type && block.type.typeName === "Ausflug" ? (
                            <>{blockText}</>
                          ) : (
                            <Text>
                              {blockText.length < 13 ? (
                                <Text style={{ fontSize: "11pt" }}>
                                  {blockText}
                                </Text>
                              ) : (
                                <Text>{blockText}</Text>
                              )}
                            </Text>
                          )
                        }
                        {block.type && block.type.image && (
                          <Image src={block.type.image} />
                        )}
                      </>
                    )}
                  </View>
                </View>
              );
            })}
          </>
        )}
      </View>
    </View>
  );
};

export default DayViewPDF;

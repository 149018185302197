import React from "react";
import styled from "styled-components";

import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
} from "@react-pdf/renderer";
import DayViewPDF from "./DayViewPDF";
import { carer } from "../defaults";

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: "#FFFFFF",
    padding: "0.5cm",
  },
  flex: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  styledDate: {
    fontSize: "20px",
    fontWeight: "bold",
    paddingLeft: "10px",
  },
  flexVertical: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
  },
  header: {
    backgroundColor: "#dce5f0",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "0.2cm",
    marginBottom: "0.2cm",
  },
  headerItem: {
    backgroundColor: "#edf3f6",
    maxWidth: "33%",
    padding: "0.1cm",
  },
  title: {
    fontSize: "12pt",
    marginBottom: "0.2cm",
  },
  smallText: {
    fontSize: "9pt",
  },
  footer: {
    backgroundColor: "#dce5f0",
    padding: "0.2cm",
  },
});

const StyledPDFViewer = styled(PDFViewer)`
  width: 100%;
  height: 800px;
`;

function groupBy(list: any, keyGetter: any) {
  const map = new Map();
  list.forEach((item: any) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  const arr = Array.from(map.values());
  return arr;
}

function filterMeals(days: any) {
  const newDays = days.map((day: any) => {
    const meals = day.map((block: any) => {
      return block.meal;
    });
    const cleaneddata = meals.filter(function (element: any) {
      return element !== undefined && element !== "";
    });
    const dayWithMeals = {
      blocks: day,
      meals: cleaneddata,
    };
    return dayWithMeals;
  });
  return newDays;
}

type ChaletViewPDFProps = {
  data: any;
  members: any;
  daysPerSheet: any;
  actualGroup?: any;
};
export const ChaletViewPDF: React.FC<ChaletViewPDFProps> = ({
  data,
  members,
  daysPerSheet,
  actualGroup,
}) => {
  const days = groupBy(data.blocks, (block: any) => block.date);
  const group = actualGroup;
  const daysWithMeals = filterMeals(days);

  const carerForChalet = carer[group].find(
    (item: any) => item.chalet === data.chaletNr.trim()
  );

  return (
    <StyledPDFViewer>
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.header}>
            <View style={styles.headerItem}>
              <Text style={styles.title}>Programme pour Cavalaire {group}</Text>
              <Text style={styles.title}>
                du{" "}
                {group === "TG"
                  ? "25.3.2023 au 8.4.2023"
                  : "8.4.2023 au 21.4.2023"}
              </Text>
              <Text style={styles.smallText}>
                Abfahrt am{" "}
                {group === "TG"
                  ? "Freitagabend 24.3.23"
                  : "Freitagabend 7.4.23"}{" "}
                gemäss Busliste Alle Programmteile in diesem Plan sind
                obligatorisch!
              </Text>
            </View>
            <View style={styles.headerItem}>
              <Text style={styles.title}>
                Chalet:{" "}
                <Text style={{ fontWeight: "bold", fontSize: "20pt" }}>
                  {data.chaletNr}
                </Text>
              </Text>
              <Text style={styles.smallText}>
                Betreuungsperson:{" "}
                {carerForChalet !== undefined && carerForChalet.name}
              </Text>
            </View>
            <View style={styles.headerItem}>
              <Text style={styles.title}>Élèves: </Text>
              <Text style={styles.smallText}>
                {members
                  .filter((member: any) => {
                    return (
                      +member.chalet === +data.chaletNr &&
                      member.group === group
                    );
                  })
                  .map((member: any) => {
                    return (
                      <>
                        {member.prename} {member.name}
                        {"\n"}
                      </>
                    );
                  })}
              </Text>
            </View>
          </View>
          <View style={styles.flex}>
            {daysWithMeals.map((day: any, index: any) => {
              if (index > daysPerSheet - 1) {
                return null;
              }
              return <DayViewPDF day={day} />;
            })}
          </View>
          <View style={styles.footer}>
            <Text style={styles.smallText}>
              {group === "TG" ? "Samstag 8.4.23" : "Freitag 21.4.23"}: Aufräumen
              und Chalets reinigen nach speziellem Plan.{" "}
              {group === "TG" &&
                "Rundgang durch Cavalaire mit den neuen St. Galler Schülern."}{" "}
              Schlussabend im Restaurant. Abfahrt um 23.00 Uhr.
            </Text>
          </View>
          <View style={styles.footer}>
            <Text style={styles.smallText}>
              {group === "TG" ? "Sonntag 9.4.23" : "Samstag 22.4.23"}: Ankunft
              in der Schweiz gegen 10:00 Uhr.
            </Text>
          </View>
        </Page>
      </Document>
    </StyledPDFViewer>
  );
};

export default ChaletViewPDF;

import React from "react";
import styled from "styled-components";

import {
  Page,
  Text,
  Image,
  View,
  Document,
  StyleSheet,
  PDFViewer,
} from "@react-pdf/renderer";

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: "#FFFFFF",
    padding: "0.5cm",
  },
  flex: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  styledDate: {
    fontSize: "20px",
    fontWeight: "bold",
    paddingLeft: "10px",
  },
  flexVertical: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
  },
  header: {
    backgroundColor: "#dce5f0",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "0.2cm",
    marginBottom: "0.2cm",
  },
  headerItem: {
    backgroundColor: "#edf3f6",
    maxWidth: "33%",
    padding: "0.1cm",
  },
  title: {
    fontSize: "12pt",
    marginBottom: "0.2cm",
  },
  smallText: {
    fontSize: "9pt",
  },
  footer: {
    backgroundColor: "#dce5f0",
    padding: "0.2cm",
  },
});

const StyledPDFViewer = styled(PDFViewer)`
  width: 100%;
  height: 800px;
`;

function styledChaletGroup(chaletGroup: any) {
  let counter = 0;
  return chaletGroup.map((chalet: any) => {
    return (
      <View style={{ height: "50%" }}>
        <Text
          style={{ fontSize: "30pt", fontWeight: "bold", textAlign: "center" }}
        >
          {chalet[0].chalet}{" "}
          <Text style={{ fontSize: "20pt", fontWeight: "normal" }}>
            {chalet[0].group}
          </Text>
        </Text>
        <View style={styles.flex}>
          {chalet.map((person: any) => {
            return (
              <View style={{ width: "33%", height: "6cm" }}>
                <View
                  style={{
                    height: "6cm",
                    textAlign: "center",
                    marginBottom: "0.2cm",
                  }}
                >
                  <Image
                    style={{
                      height: "100%",
                      objectFit: "contain",
                    }}
                    src={`https://intra.sprachlager.fr/user_img/${encodeURI(
                      person.prename + " " + person.name
                    )}.jpg`}
                  />
                </View>
                <Text style={{ textAlign: "center", marginBottom: "0.2cm" }}>
                  {person.prename} {person.name}
                  {"\n"}
                </Text>
              </View>
            );
          })}
        </View>
      </View>
    );
  });
}

function groupBy(list: any, keyGetter: any) {
  const map = new Map();
  list.forEach((item: any) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  const arr = Array.from(map.values());
  return arr;
}

type ChaletSpiegelViewPDFProps = {
  members: any;
};
export const ChaletSpiegelViewPDF: React.FC<ChaletSpiegelViewPDFProps> = ({
  members,
}) => {
  const membersPerGroup = groupBy(members, (members: any) => members.group);

  const firstGroupPerChalet = groupBy(
    membersPerGroup[0],
    (members: any) => members.chalet
  );
  const secondGroupPerChalet = groupBy(
    membersPerGroup[1],
    (members: any) => members.chalet
  );

  console.log("membersPerGroup", membersPerGroup);

  return (
    <StyledPDFViewer>
      <Document>
        <Page size="A4" style={styles.page}>
          {styledChaletGroup(firstGroupPerChalet)}
        </Page>

        <Page size="A4" style={styles.page}>
          {styledChaletGroup(secondGroupPerChalet)}
        </Page>
      </Document>
    </StyledPDFViewer>
  );
};

export default ChaletSpiegelViewPDF;

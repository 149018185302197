export function useFormatMemberlists(memberList: any) {
  const headerRow = 2;
  // last column to import
  const lastImportCol = 16;

  // if col with geschlecht is empty, there is no human being to get in big list
  const chaletCol = 0;
  // if col with geschlecht is empty, there is no human being to get in big list
  const needsContentCol = 1;

  const members: any = [];

  let memberListFinished = false;
  if (memberList && memberList.rows && memberList.rows.length) {
    memberList.rows.map((row: any, rowIndex: any) => {
      //if all first chalets are worked through and the line without a chalet number was found we stop
      if (!memberListFinished) {
        // after the header we begin with the members
        if (rowIndex > headerRow) {
          // if a chalet is defined
          if (
            row.cells[chaletCol].value !== undefined &&
            row.cells[chaletCol].value.trim().length === 3
          ) {
            // there should be a member in this row
            if (row.cells[needsContentCol].value !== undefined) {
              const member = {
                chalet: row.cells[0].value,
                gender: row.cells[1].value,
                group: row.cells[2].value,
                prename: row.cells[3].value,
                name: row.cells[4].value,
                street: row.cells[5].value,
                zip: row.cells[6].value,
                city: row.cells[7].value,

                birthday: row.cells[8].value,
              };
              members.push(member);
            }
          } else {
            //memberListFinished = true;
          }
        }
      }
    });
  }
  console.log("members", members);
  return members;
}

import React, { useState } from "react";
import { blockTypes, dayTitles, teachers } from "../defaults";
import Chalet from "./Chalet";

type MasterplanProps = {
  data: any;
  members: any;
};
export const Masterplan: React.FC<MasterplanProps> = ({ data, members }) => {
  const [actualChaletCol, setActualChaletCol] = useState(3);
  const [actualGroup, setActualGroup] = useState("TG");
  const [chaletTitleRow, setChaletTitleRow] = useState(2);
  // Settings Chalet
  const dateCol = 1;
  const timeCol = 2;
  const mealCol = 86;

  const leaderColFrom = 56; // start leader planning
  const leaderColTo = 84; // End leader planning
  const leaderRow = 3; // Leiter name

  const teacherColFrom = 90; // Start teacher planning
  const teacherColTo = 105; // End teacher planning
  const teacherRow = 13; // Shortname, ex. CP, MH, ..

  const mediathequeCol = 49;
  const salleDeReunionCol = 51;
  const cocotiersVorneCol = 52;
  const cocotiersHintenCol = 53;

  const minChaletColTG = 3;
  const maxChaletColTG = 23;

  const minChaletColSG = 24;
  const maxChaletColSG = 47;

  // settings sheet
  const daysPerSheet = 14;

  // Rows to ignore (eg kanti, ...)
  const ignorableRows = [3, 91, 92];

  return (
    <>
      <div>
        Kanton wechseln:
        <br />
        <button
          type="button"
          onClick={() => {
            setChaletTitleRow(2);
            setActualChaletCol(3);
            setActualGroup("TG");
          }}
        >
          TG
        </button>
        ////
        <button
          type="button"
          onClick={() => {
            setChaletTitleRow(90);
            setActualChaletCol(24);
            setActualGroup("SG");
          }}
        >
          SG
        </button>
      </div>
      <div>
        Chalet wechseln:
        <br />
        <button
          type="button"
          onClick={() => {
            if (actualGroup === "TG" && actualChaletCol > minChaletColTG) {
              setActualChaletCol(actualChaletCol - 1);
            }

            if (actualGroup === "SG" && actualChaletCol > minChaletColSG) {
              setActualChaletCol(actualChaletCol - 1);
            }
          }}
        >
          -
        </button>
        ////
        <button
          type="button"
          onClick={() => {
            if (actualGroup === "TG" && actualChaletCol < maxChaletColTG) {
              setActualChaletCol(actualChaletCol + 1);
            }

            if (actualGroup === "SG" && actualChaletCol < maxChaletColSG) {
              setActualChaletCol(actualChaletCol + 1);
            }
          }}
        >
          +
        </button>
      </div>
      <Chalet
        data={data}
        members={members}
        chaletTitleRow={chaletTitleRow}
        chaletCol={actualChaletCol}
        dateCol={dateCol}
        timeCol={timeCol}
        mealCol={mealCol}
        ignorableRows={ignorableRows}
        blockTypes={blockTypes}
        teachers={teachers}
        dayTitles={dayTitles}
        daysPerSheet={daysPerSheet}
        actualGroup={actualGroup}
        // check for place

        mediathequeCol={mediathequeCol}
        salleDeReunionCol={salleDeReunionCol}
        cocotiersVorneCol={cocotiersVorneCol}
        cocotiersHintenCol={cocotiersHintenCol}
        // start for debugger checks
        leaderColFrom={leaderColFrom}
        leaderColTo={leaderColTo}
        leaderRow={leaderRow}
        teacherColFrom={teacherColFrom}
        teacherColTo={teacherColTo}
        teacherRow={teacherRow}
      />
    </>
  );
};

export default Masterplan;

import { Text, View } from "@react-pdf/renderer";

export const carer: any = [];

carer["TG"] = [
  { chalet: "157", name: "Nadine Götz" },
  { chalet: "158", name: "Nadine Götz" },
  { chalet: "159", name: "Nadine Götz" },
  { chalet: "160", name: "Markus Hilber" },
  { chalet: "161", name: "Markus Hilber" },
  { chalet: "164", name: "Thomas Schallmeiner" },
  { chalet: "165", name: "Thomas Schallmeiner" },
  { chalet: "166", name: "Thomas Schallmeiner" },
  { chalet: "167", name: "Marcel Laubscher" },
  { chalet: "168", name: "Marcel Laubscher" },
  { chalet: "169", name: "Marcel Laubscher" },
  { chalet: "170", name: "Fabian Castrischer" },
  { chalet: "171", name: "Fabian Castrischer" },
  { chalet: "176", name: "Fabian Castrischer" },
  {
    chalet: "177",
    name: "Zeynep Kayisoglu (1. Woche Stellvertretung duch Sophia)",
  },
  {
    chalet: "178",
    name: "Zeynep Kayisoglu (1. Woche Stellvertretung duch Sophia)",
  },
  { chalet: "179", name: "Olga Sizykh" },
  { chalet: "180", name: "Olga Sizykh" },
  { chalet: "181", name: "Olga Sizykh" },
  { chalet: "182", name: "Max Krähenbühl" },
  { chalet: "183", name: "Max Krähenbühl" },
];

carer["SG"] = [
  { chalet: "157", name: "Bettina Lieb" },
  { chalet: "158", name: "Bettina Lieb" },
  { chalet: "159", name: "Ulla Elfrich-Hasler" },
  { chalet: "160", name: "Ulla Elfrich-Hasler" },
  { chalet: "161", name: "Ulla Elfrich-Hasler" },
  { chalet: "164", name: "Peter Greber" },
  { chalet: "165", name: "Peter Greber" },
  { chalet: "166", name: "Peter Greber" },
  { chalet: "167", name: "Sibylle Greber" },
  { chalet: "168", name: "Sibylle Greber" },
  { chalet: "169", name: "Sibylle Greber" },
  { chalet: "170", name: "René Götz" },
  { chalet: "171", name: "Barbara Styger" },
  { chalet: "172", name: "Barbara Styger" },
  { chalet: "173", name: "Barbara Styger" },
  { chalet: "175", name: "Barbara Styger" },
  { chalet: "176", name: "Elisabeth Dostmann" },
  { chalet: "177", name: "Elisabeth Dostmann" },
  { chalet: "178", name: "Elisabeth Dostmann" },
  { chalet: "179", name: "Caroline Rutz" },
  { chalet: "180", name: "Caroline Rutz" },
  { chalet: "181", name: "Caroline Rutz" },
  { chalet: "182", name: "Felix Styger" },
  { chalet: "183", name: "Felix Styger" },
];

export const daysToReplace: any = [];
daysToReplace["Samedi, 25.3."] = {
  content: (
    <View style={{ fontSize: "8pt", marginRight: "0.4cm" }}>
      <Text style={{ marginBottom: "0.4cm" }}>
        09:00 h Ankunft der 2 Busse TG, Gepäck ausladen, Chaletbezug und
        Einräumen.
      </Text>

      <Text style={{ marginBottom: "0.4cm" }}>
        10:00 h Begrüssung und Zmorgebüffet.
      </Text>
      <Text style={{ marginBottom: "0.4cm" }}>
        11:00 h Chalets fertig einräumen und erster Erkundungs-Spaziergang auf
        dem Zeltplatz.
      </Text>
      <Text style={{ marginBottom: "0.4cm" }}>
        14:00 h geführter Rundgang durch Cavalaire mit dem Chalet-Betreuer.
      </Text>

      <Text style={{ marginBottom: "0.4cm" }}>Nachher: frei.</Text>
    </View>
  ),
};

daysToReplace["Samedi, 8.4."] = {
  content: (
    <View style={{ fontSize: "8pt", marginRight: "0.4cm" }}>
      <Text style={{ marginBottom: "0.4cm" }}>
        09:00 h Ankunft der 2 Busse SG, Gepäck ausladen und ins Chalet stellen.
      </Text>

      <Text style={{ marginBottom: "0.4cm" }}>
        10:00 h Begrüssung und Zmorgebüffet. Anschliessend Einweisung und
        Führung durch den Zeltplatz und ins Städtchen Cavalaire mit einer noch
        anwesenden Gruppe der Thurgauer, nach speziellem Plan.
      </Text>
      <Text style={{ marginBottom: "0.4cm" }}>
        18:00 Uhr Abendessen, anschliessend frei.
      </Text>
    </View>
  ),
};

export const dayTitles: any = [];
dayTitles["Sa25."] = {
  shortName: "Sa 25.",
  name: "Samedi, 25.3.",
};

dayTitles["So26."] = {
  shortName: "So 26.",
  name: "Dimanche, 26.3.",
};

dayTitles["Mo27."] = {
  shortName: "Mo 27.",
  name: "Lundi, 27.3.",
};

dayTitles["Di28."] = {
  shortName: "Di 28.",
  name: "Mardi, 28.3.",
};

dayTitles["Mi29."] = {
  shortName: "Mi 29.",
  name: "Mercredi, 29.3.",
};

dayTitles["Do30."] = {
  shortName: "Do 30.",
  name: "Jeudi, 30.3.",
};

dayTitles["Fr31."] = {
  shortName: "Fr 31.",
  name: "Vendredi, 31.3.",
};

dayTitles["Sa1."] = {
  shortName: "Sa 1.",
  name: "Samedi, 1.4.",
};

dayTitles["So2."] = {
  shortName: "So 2.",
  name: "Dimanche, 2.4.",
};

dayTitles["Mo3."] = {
  shortName: "Mo 3.",
  name: "Lundi, 3.4.",
};

dayTitles["Di4."] = {
  shortName: "Di 4.",
  name: "Mardi, 4.4.",
};

dayTitles["Mi5."] = {
  shortName: "Mi 5.",
  name: "Mercredi, 5.4.",
};

dayTitles["Do6."] = {
  shortName: "Do 6.",
  name: "Jeudi, 6.4.",
};

dayTitles["Fr7."] = {
  shortName: "Fr 7.",
  name: "Vendredi, 7.4.",
};

dayTitles["Sa8."] = {
  shortName: "Sa 8.",
  name: "Samedi, 8.4.",
};

dayTitles["So9."] = {
  shortName: "So 9.",
  name: "Dimanche, 9.4.",
};

dayTitles["Mo10."] = {
  shortName: "Mo 10.",
  name: "Lundi, 10.4.",
};

dayTitles["Di11."] = {
  shortName: "Di 11.",
  name: "Mardi, 11.4.",
};

dayTitles["Mi12."] = {
  shortName: "Mi 12.",
  name: "Mercredi, 12.4.",
};

dayTitles["Do13."] = {
  shortName: "Do 13.",
  name: "Jeudi, 13.4.",
};

dayTitles["Fr14."] = {
  shortName: "Fr 14.",
  name: "Vendredi, 14.4.",
};

dayTitles["Sa15."] = {
  shortName: "Sa 15.",
  name: "Samedi, 15.4.",
};

dayTitles["So16."] = {
  shortName: "So 16.",
  name: "Dimanche, 16.4.",
};

dayTitles["Mo17."] = {
  shortName: "Mo 17.",
  name: "Lundi, 17.4.",
};

dayTitles["Di18."] = {
  shortName: "Di 18.",
  name: "Mardi, 18.4.",
};

dayTitles["Mi19."] = {
  shortName: "Mi 19.",
  name: "Mercredi, 19.4.",
};

dayTitles["Do20."] = {
  shortName: "Do 20.",
  name: "Jeudi, 20.4.",
};

dayTitles["Fr21."] = {
  shortName: "Fr 21.",
  name: "Vendredi, 21.4.",
};

export const teachers: any = [];
teachers["CP"] = {
  shortName: "CP",
  name: "Chantal Paxion",
};
teachers["MH"] = {
  shortName: "MH",
  name: "Murielle Herbaux",
};
teachers["EC"] = {
  shortName: "EC",
  name: "Edith Coiffard",
};
teachers["CG"] = {
  shortName: "CG",
  name: "Céline Gontier",
};
teachers["SV"] = {
  shortName: "SV",
  name: "Stéphanie Viegas",
};
teachers["FB"] = {
  shortName: "FB",
  name: "Françoise Baumet",
};
teachers["MM"] = {
  shortName: "MM",
  name: "Martine Mairet",
};
teachers["ST"] = {
  shortName: "ST",
  name: "Sylvie Torossian",
};
teachers["AD"] = {
  shortName: "AD",
  name: "Aurore Debackere",
};
teachers["SL"] = {
  shortName: "SL",
  name: "Serge Lecaplain",
};
teachers["YE"] = {
  shortName: "YE",
  name: "Yvonne da Encarnaçao",
};
teachers["NB"] = {
  shortName: "NB",
  name: "Nativité Barbedet",
};
teachers["ML"] = {
  shortName: "ML",
  name: "Muriel Lafarie",
};
teachers["AB"] = {
  shortName: "AB",
  name: "Annie Barattini",
};
teachers["DL"] = {
  shortName: "DL",
  name: "Daniel Losfeld",
};
teachers["HP"] = {
  shortName: "HP",
  name: "Hélène Parienti",
};
teachers["nn"] = {
  shortName: "nn",
  name: "NOT DEFINED",
};

export const blockTypes: any = [];
blockTypes["Voc 1"] = {
  typeName: "Voc1",
  text: "Atelier Vocabulaire",
  color: "#cb9cfd",
  needsLeader: 1,
  needsTeacher: 0,
  combineWithNextBlock: true,
};
blockTypes["Voc 2"] = {
  typeName: "Voc 2",
  text: "Atelier Vocabulaire 2",
  color: "#cb9cfd",
  needsLeader: 1,
  needsTeacher: 0,
  combineWithNextBlock: true,
};
blockTypes["Cinéma"] = {
  typeName: "Cinéma",
  text: "Atelier Cinéma",
  color: "#cb9cfd",
  needsLeader: 1,
  needsTeacher: 0,
  combineWithNextBlock: true,
};
blockTypes["Verbes"] = {
  typeName: "Verbes",
  text: "Atelier Verbes",
  color: "#cb9cfd",
  needsLeader: 1,
  needsTeacher: 0,
  combineWithNextBlock: true,
};

blockTypes["At. P.C."] = {
  typeName: "At. P.C.",
  text: "Atelier passé composé",
  color: "#cb9cfd",
  needsLeader: 1,
  needsTeacher: 0,
  combineWithNextBlock: true,
};

blockTypes["Jeux"] = {
  typeName: "Jeux",
  text: "Atelier Jeux",
  color: "#cb9cfd",
  needsLeader: 1,
  needsTeacher: 0,
  combineWithNextBlock: true,
};

blockTypes["T1"] = {
  typeName: "T1",
  text: "Test 1",
  color: "#fc0b1c",
  needsLeader: 1,
  needsTeacher: 0,
  combineWithNextBlock: true,
};
blockTypes["T2"] = {
  typeName: "T2",
  text: "Test 2",
  color: "#fc0b1c",
  needsLeader: 1,
  needsTeacher: 0,
  combineWithNextBlock: true,
};
blockTypes["T3"] = {
  typeName: "T3",
  text: "Test 3",
  color: "#fc0b1c",
  needsLeader: 1,
  needsTeacher: 0,
  combineWithNextBlock: true,
};
blockTypes["Boule"] = {
  typeName: "Boule",
  text: "Boule",
  color: "#e16c22",
  needsLeader: 1,
  needsTeacher: 0,
  combineWithNextBlock: true,
};
blockTypes["Bouleturnier"] = {
  typeName: "Bouleturnier",
  text: "Bouleturnier",
  color: "#e16c22",
  needsLeader: 1,
  needsTeacher: 0,
  combineWithNextBlock: true,
};
blockTypes["Médiath"] = {
  typeName: "Mediath",
  text: "Médiathèque, Chalet 184",
  color: "#cb9cfd",
  needsLeader: 1,
  needsTeacher: 0,
  combineWithNextBlock: true,
};
blockTypes["AtGr 1"] = {
  typeName: "AtGr 1",
  text: "Atelier Grammaire 1",
  color: "#cb9cfd",
  needsLeader: 1,
  needsTeacher: 0,
  combineWithNextBlock: true,
};
blockTypes["AtGr 2"] = {
  typeName: "AtGr 2",
  text: "Atelier Grammaire 2",
  color: "#cb9cfd",
  needsLeader: 1,
  needsTeacher: 0,
  combineWithNextBlock: true,
};
blockTypes["St-Tr"] = {
  typeName: "AtGr 2",
  text: "St-Tropez, vorbereitung für den Besuch der Citadelle",
  color: "#cb9cfd",
  needsLeader: 0,
  needsTeacher: 0,
  combineWithNextBlock: true,
};
blockTypes["Probet."] = {
  typeName: "T2Pro",
  text: "Probetest lösen",
  color: "#dda4a3",
  needsLeader: 0,
  needsTeacher: 0,
  combineWithNextBlock: true,
};
blockTypes["Probet. 2 gelöst?"] = {
  typeName: "T2Pro",
  text: "Probetest 2 lösen",
  color: "#dda4a3",
  needsLeader: 1,
  needsTeacher: 0,
  combineWithNextBlock: true,
};
blockTypes["Fitness"] = {
  typeName: "Fit",
  text: "Fitness",
  color: "#b7dde7",
  needsLeader: 1,
  needsTeacher: 0,
  combineWithNextBlock: true,
};
blockTypes["Yoga"] = {
  typeName: "Yoga",
  text: "Yoga",
  color: "#b7dde7",
  needsLeader: 1,
  needsTeacher: 0,
  combineWithNextBlock: true,
};
blockTypes["Conv"] = {
  typeName: "Conv",
  text: "Conversation",
  color: "#fffd38",
  needsLeader: 0,
  needsTeacher: 1,
  combineWithNextBlock: false,
};

blockTypes["Cuisine"] = {
  typeName: "Cuisine",
  text: "Cuisine française",
  color: "#a5cc4d",
  needsLeader: 0,
  needsTeacher: 1,
  combineWithNextBlock: false,
};

blockTypes["Volley"] = {
  typeName: "Volley",
  text: "Volleyball",
  color: "#a5cc4d",
  needsLeader: 1,
  needsTeacher: 0,
  combineWithNextBlock: false,
};

blockTypes["Fussb"] = {
  typeName: "Fussb",
  text: "Fussball",
  color: "#a5cc4d",
  needsLeader: 1,
  needsTeacher: 0,
  combineWithNextBlock: false,
};

blockTypes["Disco"] = {
  typeName: "Disco",
  text: "Discoabend",
  color: "#fd3999",
  needsLeader: 0,
  needsTeacher: 0,
  combineWithNextBlock: true,
};
blockTypes["Casino"] = {
  typeName: "Casino",
  text: "Casinoabend",
  color: "#fd3999",
  needsLeader: 1,
  needsTeacher: 0,
  combineWithNextBlock: true,
};
blockTypes["Marché"] = {
  typeName: "Marché",
  text: "Marché",
  color: "#a5cc4d",
  needsLeader: 0,
  needsTeacher: 0,
  combineWithNextBlock: true,
};
blockTypes["Spiele"] = {
  typeName: "Spiele",
  text: "Spielabend",
  color: "#FCD5B4",
  needsLeader: 1,
  needsTeacher: 0,
  combineWithNextBlock: true,
};
blockTypes["Dinner"] = {
  typeName: "Dinner",
  text: "Das perfekte Dinner",
  color: "#a28a90",
  needsLeader: 0,
  needsTeacher: 0,
  combineWithNextBlock: true,
};
blockTypes["Schlussabend"] = {
  typeName: "Schlussabend",
  text: "Schlussabend im Restaurant 'Les Cocotiers'",
  color: "#1eb1ee",
  needsLeader: 1,
  needsTeacher: 0,
  combineWithNextBlock: true,
};
blockTypes["Plongée"] = {
  typeName: "Plongée",
  text: "Plongée",
  color: "#51adc5",
  image: "https://intra.sprachlager.fr/application_img/Tauchen.jpg",
  needsLeader: 1,
  needsTeacher: 0,
  combineWithNextBlock: true,
};
blockTypes["Plage"] = {
  typeName: "Plage",
  text: "Plage - Grosses Volleyballturnier am Strand",
  color: "#febe2d",
  needsLeader: 1,
  needsTeacher: 0,
  combineWithNextBlock: true,
};
blockTypes["Ausflug"] = {
  typeName: "Ausflug",
  text: "Excursion",
  color: "#55afc6",
  needsLeader: 0,
  needsTeacher: 0,
  combineWithNextBlock: true,
};
blockTypes["Cuisine"] = {
  typeName: "Cuisine",
  text: "Kochen mit franz. Lehrperson",
  color: "#ee2ea7",
  needsLeader: 0,
  needsTeacher: 1,
  combineWithNextBlock: true,
};
blockTypes["Rally"] = {
  typeName: "Rally",
  text: "Rally durch Cavalaire (für alle), Startzeiten am Anschlagbrett",
  color: "#ee2ea7",
  needsLeader: 0,
  needsTeacher: 0,
  combineWithNextBlock: true,
};

blockTypes["Kuechendienst"] = {
  typeName: "Kue",
  text: "Küchendienst",
  color: "#f0ffea",
  needsLeader: 0,
  needsTeacher: 0,
  combineWithNextBlock: false,
};

blockTypes["Einladungen"] = {
  typeName: "Einladungen",
  text: "Vorschlag: Freunde zum Essen und Spielen einladen.",
  color: "#FFF",
  needsLeader: 0,
  needsTeacher: 0,
  combineWithNextBlock: false,
};

blockTypes["Ausschlafen"] = {
  typeName: "Ausschlafen",
  text: "Ausschlafen bis 09:00, 10:00 Brunchbüffet",
  color: "#FFF",
  needsLeader: 0,
  needsTeacher: 0,
  combineWithNextBlock: false,
};

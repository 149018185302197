import React, { useState } from "react";
import ChaletViewPDF from "./ChaletViewPDF";

type ChaletProps = {
  data: any;
  members: any;
  chaletTitleRow: number;
  chaletCol: number;
  dateCol: number;
  timeCol: number;
  mealCol: number;
  ignorableRows: any;
  blockTypes: any;
  teachers: any;
  dayTitles: any;
  daysPerSheet: any;
  actualGroup: any;
  leaderColFrom: any;
  leaderColTo: any;
  leaderRow: any;
  teacherColFrom: any;
  teacherColTo: any;
  teacherRow: any;
  mediathequeCol: any;
  salleDeReunionCol: any;
  cocotiersVorneCol: any;
  cocotiersHintenCol: any;
};
export const Chalet: React.FC<ChaletProps> = ({
  data,
  members,
  chaletTitleRow,
  chaletCol,
  dateCol,
  timeCol,
  mealCol,
  ignorableRows,
  blockTypes,
  teachers,
  dayTitles,
  daysPerSheet,
  actualGroup,

  leaderColFrom,
  leaderColTo,
  leaderRow,
  teacherColFrom,
  teacherColTo,
  teacherRow,

  mediathequeCol,
  salleDeReunionCol,
  cocotiersVorneCol,
  cocotiersHintenCol,
}) => {
  // cleaned chalet data
  let chaletData: any = {};
  const [lessonCheckerEnabled, setLessoncheckerEnabled] = useState(false);

  data.rows.map((row: any, rowIndex: any) => {
    // if row is ignorable
    if (ignorableRows.includes(rowIndex)) {
      return null;
    }

    if (chaletTitleRow === rowIndex) {
      // Get chalet nr / title

      row.cells.map((cell: any, colIndex: any) => {
        if (colIndex === chaletCol) {
          // extract chalet nr
          const chaletNr = cell.value
            .replace(/ *\([^)]*\) */g, "") // Remove everything in ()
            .trim() // remove all whitespace
            .substring(2) // remove first 2 letters (EG. TG/SG)
            .substring(3, 7); // leave just 3 chars

          chaletData = { chaletNr, chaletCol, blocks: [] };
        }
      });

      // All after Chalettitle
    } else if (chaletTitleRow < rowIndex) {
      let date = "";
      let time = "";

      row.cells.map((cell: any, colIndex: any) => {
        let meal = "";
        // get time of row
        if (colIndex === timeCol) {
          time = cell.value;
          if (cell.value && cell.value.includes("- 11")) {
            time = "09:00 - 10:50 h";
          }
          if (cell.value && cell.value.includes("- 13")) {
            time = "11:00 - 12:50 h";
          }
          if (cell.value && cell.value.includes("- 16")) {
            time = "14:00 - 15:50 h";
          }

          if (cell.value && cell.value.includes("- 18")) {
            time = "16:00 - 17:50 h";
          }
          if (cell.value && cell.value.includes("- 21")) {
            time = "19:00 - 20:50 h";
          }
        }

        if (colIndex === mealCol && cell.value !== undefined) {
          meal = cell.value;
        }
        if (colIndex === dateCol) {
          // get date of row
          //date = cell.value;
          date =
            (cell.value !== undefined &&
              dayTitles[cell.value.replace(/(\r\n|\n|\r)/gm, "")] &&
              dayTitles[cell.value.replace(/(\r\n|\n|\r)/gm, "")].name) ||
            cell.value;
        }
        if (colIndex === chaletCol) {
          // Just all Elements for the chosen chalet
          const color = (cell.style && cell.style.backgroundColor) || "";
          let content = cell.value || "";
          let type = blockTypes[cell.value && cell.value.trim()];

          // Conversations
          if (
            cell.value &&
            cell.value.includes("/") &&
            cell.style &&
            cell.style.backgroundColor === "#FFFF00"
          ) {
            const teacherSubject = cell.value.split("/");
            let teacherName = "Undefined!";
            if (
              teachers[teacherSubject[0].trim()] !== undefined &&
              teachers[teacherSubject[0].trim()].name !== undefined
            ) {
              teacherName = teachers[teacherSubject[0].trim()].name;
            }
            type = {
              ...blockTypes["Conv"],
              teacher: teacherName,
              teacherShort: teacherSubject[0].trim(),
              subject: teacherSubject[1],
            };
          }
          // End Conversations

          // Cuisine FR
          if (
            cell.value &&
            cell.value.includes("Cuisine") &&
            cell.style &&
            cell.style.backgroundColor === "#00B050"
          ) {
            const splittedText = cell.value.split(" ");
            let teacherName = "Undefined!";
            if (
              teachers[splittedText[1].trim()] !== undefined &&
              teachers[splittedText[1].trim()].name !== undefined
            ) {
              teacherName = teachers[splittedText[1].trim()].name;
            }
            type = {
              ...blockTypes["Cuisine"],
              teacher: teacherName,
            };
          }
          // End Cuisine FR

          // Küchendienst
          if (
            cell.value &&
            cell.value.includes("Kü ") &&
            cell.style
            //&&
            //cell.style.backgroundColor === "#00B050"
          ) {
            const splittedText = cell.value.split(" ");

            type = {
              ...blockTypes["Kuechendienst"],
              specialTime: splittedText[1],
            };
          }
          // End Küchendienst

          // Excoursion
          if (
            (cell.value && cell.value.includes("Exc")) ||
            (cell.value &&
              cell.style &&
              cell.style.backgroundColor === "#333399")
          ) {
            let destination = "";
            if (cell.value.includes("Grim")) {
              destination = "Port Grimaud";
            }
            if (cell.value.includes("Tropez")) {
              destination = "St-Tropez";
              content = "nach speziellem Plan";
            }
            if (cell.value.includes("Nice")) {
              destination = "Nice";
            }
            type = {
              ...blockTypes["Ausflug"],
              destination,
            };
          }
          // End Excoursion

          // Specially named types
          if (type === undefined && cell.value) {
            if (cell.value.includes("DISCOABEND")) {
              type = blockTypes["Disco"];
            }
            if (cell.value.includes("Rally")) {
              type = blockTypes["Rally"];
            }
            if (cell.value.includes("PLAGE")) {
              type = blockTypes["Plage"];
            }
            if (cell.value.includes("SPIELABEND")) {
              type = blockTypes["Spiele"];
            }
            if (cell.value.includes("Das perfekte Dinner")) {
              type = blockTypes["Dinner"];
            }
            if (cell.value.includes("CASINOABEND")) {
              type = blockTypes["Casino"];
            }
            if (cell.value.includes("Plongée")) {
              type = blockTypes["Plongée"];
            }
            if (cell.value.includes("Soirée des Adieux")) {
              type = blockTypes["Schlussabend"];
            }
            if (cell.value.includes("Boule-Turnier")) {
              type = blockTypes["Bouleturnier"];
            }
            if (cell.value.includes("gegenseitige Einladungen")) {
              type = blockTypes["Einladungen"];
            }
            if (cell.value.includes("Ausschlafen bis ca")) {
              type = blockTypes["Ausschlafen"];
            }
          }

          chaletData.blocks.push({
            date,
            time,
            meal,
            content,
            excelColor: color,
            excelRow: rowIndex,
            type,
          });
        } else if (meal !== "") {
          chaletData.blocks.push({
            date,
            meal,
          });
        }
      });
    }
  });

  return (
    <>
      <div
        onClick={() => {
          setLessoncheckerEnabled(!lessonCheckerEnabled);
        }}
      >
        Debugger de/aktivieren
      </div>
      {lessonCheckerEnabled && (
        <>
          Automated checks:
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {chaletData.blocks.map((block: any) => {
              // if no checks were made, display white bg
              let checksWereMade = false;
              let errorMsg = "";
              let warnMsg = "";
              const chaletNr = chaletData.chaletNr.trim();

              if (block.type) {
                // check blocks if leaders are present
                if (block.type.needsLeader) {
                  let leadersFound = 0;
                  checksWereMade = true;
                  data.rows[block.excelRow].cells.map(
                    (cell: any, index: any) => {
                      if (
                        index < leaderColTo + 1 &&
                        index > leaderColFrom - 1 &&
                        cell.value
                      ) {
                        if (block.excelColor === cell.style.backgroundColor) {
                          console.log(
                            "same color found! Possible Leader?",
                            block.excelColor,
                            cell.style.backgroundColor
                          );
                          leadersFound += 1;
                        }
                      }
                    }
                  );
                  if (leadersFound === 0) {
                    warnMsg += "keinen Leiter für diesen Block gefunden";
                  }
                }

                if (block.type.typeName === "Conv") {
                  checksWereMade = true;
                  // Checks for conversation blocks
                  if (block.type.teacher === "NOT DEFINED") {
                    errorMsg += "Lehrer nicht definiert! ";
                  }

                  // check if any teacher has this chalet in his plan

                  let teachersFound = 0;
                  data.rows[block.excelRow].cells.map(
                    (cell: any, index: any) => {
                      if (
                        index < teacherColTo + 1 &&
                        index > teacherColFrom - 1 &&
                        cell.value &&
                        cell.value.includes("/")
                      ) {
                        const teacherChalet = cell.value.split("/")[0];
                        const teacherSubject = cell.value.split("/")[1];

                        if (chaletNr === teacherChalet) {
                          teachersFound += 1;

                          if (teacherSubject !== block.type.subject) {
                            errorMsg +=
                              "Thema Lehrer / Thema Chalet stimmt nicht überein!";
                          }
                          const teacherShortName =
                            data.rows[teacherRow].cells[index].value;

                          if (teacherShortName !== block.type.teacherShort) {
                            errorMsg +=
                              "Lehrperson stimmt nicht mit Stundenplan überein!";
                          }
                        }
                      }
                    }
                  );
                  if (teachersFound === 0) {
                    errorMsg += "keinen Lehrer für diese Lektion eingeteilt!";
                  }
                  if (teachersFound > 1) {
                    errorMsg +=
                      "Mehrere Lehrer für diese Lektion zugeteilt! (" +
                      teachersFound +
                      " Lehrer)";
                  }
                }
                return (
                  <div
                    style={{
                      border: "1px solid black",
                      marginBottom: "10px",
                      color: "white",
                      padding: "5px",
                      width: "100px",
                      backgroundColor:
                        checksWereMade === true
                          ? errorMsg !== ""
                            ? "red"
                            : warnMsg !== ""
                            ? "orange"
                            : "green"
                          : "grey",
                    }}
                  >
                    <div style={{ fontSize: "9px", lineHeight: "9px" }}>
                      {block.date} - {block.time}
                    </div>
                    Blocktyp: "{block.type.typeName}"
                    {block.type.typeName === "Conv" &&
                      "Lehrer: " +
                        block.type.teacher +
                        " Thema:" +
                        block.type.subject}
                    {errorMsg && (
                      <div style={{ fontWeight: "bold" }}>
                        <br />
                        <br /> Fehler: {errorMsg}
                      </div>
                    )}
                    {warnMsg && (
                      <div>
                        <br />
                        <br /> Warnung: {warnMsg}
                      </div>
                    )}
                  </div>
                );
              }
            })}
          </div>
        </>
      )}
      <ChaletViewPDF
        data={chaletData}
        daysPerSheet={daysPerSheet}
        members={members}
        actualGroup={actualGroup}
      />
    </>
  );
};

export default Chalet;

import React from "react";
import Masterplan from "./Masterplan";

type ExcelDataProps = {
  masterplan: any;
  members: any;
};
export const ExcelData: React.FC<ExcelDataProps> = ({
  masterplan,
  members,
}) => {
  let dataToWorkWith = { ...masterplan };

  dataToWorkWith.rows.map((row: any, rowIndex: any) => {
    row.cells.map((cell: any, colIndex: any) => {
      // connected cells value  to all connected cells
      if (cell.colSpan !== undefined && cell.rowSpan !== undefined) {
        for (var i = 0; i < cell.colSpan; i++) {
          dataToWorkWith.rows[rowIndex].cells[colIndex + i].value = cell.value;
          for (var i2 = 0; i2 < cell.rowSpan; i2++) {
            dataToWorkWith.rows[rowIndex + i2].cells[colIndex + i].value =
              cell.value;
          }
        }
      } else if (cell.colSpan !== undefined) {
        for (var i = 0; i < cell.colSpan; i++) {
          dataToWorkWith.rows[rowIndex].cells[colIndex + i].value = cell.value;
        }
      } else if (cell.rowSpan !== undefined) {
        for (var i = 0; i < cell.rowSpan; i++) {
          dataToWorkWith.rows[rowIndex + i].cells[colIndex].value = cell.value;
        }
      }
    });
  });
  //console.log("raw excel data after corrections", dataToWorkWith);
  return (
    <>
      <Masterplan data={dataToWorkWith} members={members} />
    </>
  );
};

export default ExcelData;
